.titulo {
	font-size: 2em;
	color: #333;
	text-align: center;
}

#contacto-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

label.contacto {
	display: block;
	width: 50%;
	height: 30px;
	padding: 7px 10px;
	background-color: #008;
	color: #efefef;
	text-transform: uppercase;
	font-family: "Anton";
	font: 1em;
	line-height: 1;
	text-align: center;
	margin: 0 auto;
}
.anchoprincipal {
	width: 100%;
}
.input {
	float: left;
	border-radius: 5px 0 0 5px;
	width: 110px;
}

.g-recaptcha {
	height: 120px;
}
input[type="text"],
input[type="email"],
input[type="tel"] {
	display: block;
	width: calc(100% - 110px);
	height: 50px;
	border: none;
	background-color: #e9e9e9;
	padding-left: 10px;
	padding-right: 10px;
}

.textarea {
	width: 100%;
	border-radius: 5px 5px 0 0;
}

textarea {
	display: block;
	width: 100%;
	min-height: 200px;
	margin: 0;
	border: none;
	background-color: #e9e9e9;
	resize: vertical;
	padding-left: 10px;
	padding-top: 10px;
	text-indent: 10px;
}

input[type="checkbox"] {
	display: inline;
	margin-left: 20px;
}

.checkbox {
	display: inline;
}

p.privacy-info {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5%;
}

.botones {
	text-align: right;
}

input[type="submit"],
input[type="reset"] {
	padding: 7px 15px;
	font-family: "Anton";
	color: #efefef;
	background-color: #008;
	border: none;
	font-weight: normal;
	font-size: 1.2em;
}

input[type="submit"]:hover,
input[type="reset"]:hover {
	background-color: #005;
	color: #fff;
}

.textarea.contacto {
	margin-top: 1.5rem;
}

#contacto-mail {
	margin-top: 3rem;
	color: white;
	cursor: pointer;
}

#captcha {
	width: 307px;
	margin-left: 5%;
	margin-top: -1rem;
}

/*********************************** responsive - movil ****************************************/

@media only screen and (max-width: 500px) {
	nav ul #menu-mobile h1 #Up2You-subtest {
		display: none;
	}

	.titulo {
		font-size: 1.4em;
		color: #333;
		text-align: center;
		padding-bottom: 5%;
	}

	label.contacto {
		width: 80%;
		min-width: 150px;
		border-radius: 5px;
		line-height: 1;
	}

	.input {
		border-radius: 5px 5px 0 0;
		width: 100%;
		font-size: 1.2em;
		line-height: 1.9;
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		width: 100%;
	}

	input[type="checkbox"],
	p.privacy-info {
		display: inline-block;
		vertical-align: middle;
		margin-left: 2%;
	}
}
