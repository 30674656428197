section > div {
	width: 100%;
}

section > hr {
	margin-top: 0 important!;
}

/* ADMIN */

#publicar-cambios {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	background-color: rgba(129, 145, 151, 0.8);
	display: flex;
	justify-content: space-evenly;
	color: white;
}

#publicar-cambios button {
	color: white;
	width: 90px;
}

#publicar-cambios button:last-child {
	background-color: red;
	margin-left: 20px;
}

#admin-form {
	background-color: rgba(169, 178, 187, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
}

#admin-form form {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Anton";
}

#admin-form label {
	margin: 20px;
	padding: 15px;
	font-size: 1em;
	color: white;
	line-height: 0;
	height: 30px;
	border-radius: 5px;
	background: #008;
	width: -moz-fit-content;
	width: fit-content;
}

#admin-form input {
	height: 30px;
	border-radius: 5px;
	border: 1px solid transparent;
	background: white;
	padding-left: 8px;
	width: 20%;
}

#admin-form input:focus {
	background-color: rgb(232, 232, 245);
}

#admin-form button {
	color: white;
	height: 40px;
	text-align: center;
	margin-left: 20px;
	font-family: "Anton";
}

#admin-form button:hover {
	cursor: pointer;
	background-color: rgb(55, 55, 164);
}

#not-succesfull {
	background-color: rgb(225, 71, 71);
	padding: 15px;
	color: white;
	border-radius: 5px;
	font-size: 1rem;
}
/* SLIDER */

.slider {
	width: 100%;
	margin-bottom: 20px;
}

#slider {
	position: relative;
}

#slider > div {
	position: absolute;
}

.slider div img {
	width: 100%;
}

#slider-image-showing {
	position: relative;
	width: 95%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#slider-image-showing img {
	margin: 0 auto;
	width: 33%;
	animation: showSlowly 2s ease-in-out forwards;
}

#slider-image-showing span {
	position: absolute;
	top: 42%;
	right: 43.5%;
	color: #000f61;
	font-weight: bold;
	text-transform: uppercase;
	background-color: rgba(255, 255, 255, 0);
}

@keyframes showSlowly {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.slider-image-hidden {
	display: none;
}
/* .underslider {
	height: 150px;
} */

/* .col2 > div {
	width: 50%;
} */

.col3 > div {
	width: 33.33333333333%;
	height: 20em;
	margin-bottom: 3em;
}

.editing-container {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.fila1home div.card-editting {
	background: rgb(158, 158, 212);
}

.fila1card span {
	position: absolute;
	top: -3%;
	right: -3%;
	z-index: 1000;
	border: 2px solid rgb(146, 153, 211);
	border-radius: 50%;
	background: rgb(204, 204, 215);
	padding: 2px;
	cursor: pointer;
}

.editing-container span {
	position: absolute;
	top: 40%;
	right: 42%;
	z-index: 1000;
	border: 2px solid rgb(146, 153, 211);
	border-radius: 50%;
	background: rgb(204, 204, 215);
	padding: 2px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 2.5em;
}

.editing-container span:hover,
.fila1card span:hover {
	background: rgb(161, 161, 205);
}

.editing-container #edit-img-input {
	position: absolute;
	top: 45%;
	left: 35%;
	z-index: 1000;
}

.editing-container textarea {
	margin-top: 20px;
	width: 80%;
	font-size: 1em;
	padding: 10px;
	height: 150px;
	line-height: 1.3em;
	border: none;
	background: rgb(201, 210, 233);
	border-radius: 5px;
	color: rgb(84, 116, 197);
}

.editing-container textarea:focus {
	outline: 1px solid rgb(48, 96, 180);
}

/* FILA 1*/

.fila1home {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
}

.fila1home .fila1card {
	display: flex;
	flex-grow: 0;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 0;
	margin: 10px 10px;
	border-radius: 10px;
	background-color: rgba(84, 128, 224, 0.2);
}

.col2 > div,
.col3 > div {
	padding: 5px;
	float: left;
	text-align: center;
}

.fila1card p {
	width: 90%;
	display: block;
	margin: 20px auto;
	font-size: 1.1em;
	color: #000f61;
}

.fila1card img {
	width: 100%;
	height: auto;
	border-radius: 5px;
}

.fila1card img:hover {
	transform: scale(1.05);
}

/* FILA 2 */
.fila2home {
	display: flex;
	width: 97%;
	justify-content: space-around;
	align-items: center;
	margin: 60px 20px 60px 0;
}
.fila2home p {
	text-align: justify;
	font-size: 1.1em;
	padding: 10px 30px;
	height: 100%;
	color: #000f61;
	background-color: white;
	margin-right: 20px;
}

.fila2home img {
	width: 500px;
}

.tips-servicios {
	padding-top: 40px;
}

.tips-servicios h3 {
	margin: 0;
	font-weight: normal;
}
.tips-servicios hr {
	margin: 0;
	width: 60%;
	margin-left: 20%;
	border-color: #999;
}
.tips-servicios p {
	text-align: justify;
	padding-left: 20%;
	padding-right: 20%;
	margin-top: 0;
	text-indent: 10px;
}

/* IDIOMQS*/
.idiomas {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
}
.idiomas > div {
	top: 0;
	margin: 10px 5% 0px 5%;
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
}

.idiomas img {
	display: block;
	width: 100%;
	height: auto;
}

.idiomas h4 {
	width: 70%;
	margin-left: 0;
	padding-left: 0;
	margin-top: 15px;
	font-weight: normal;
	text-align: center;
	line-height: 0.9;
	font-size: 1.2em;
}

.solomovil {
	display: none;
	visibility: hidden;
}

/*********************************** responsive - desktop ****************************************/

@media only screen and (min-width: 851px) {
	.fila1card {
		width: 23%;
	}

	.tips-servicios {
		height: 70vh;
		width: 100%;
	}

	.tips-servicios > div {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 33.3%;
	}

	.tips-servicios > div .servicios-subcard {
		height: 60%;
		width: 100%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.tips-servicios > div .servicios-subcard h3 {
		margin: 20px;
	}

	.tips-servicios > div .servicios-subcard p {
		width: 100%;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}

	.tips-servicios > div #slider-image-showing {
		width: 100%;
		margin-top: 5px;
	}

	.tips-servicios > div #slider-image-showing img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

/*********************************** responsive - tablet ****************************************/

@media only screen and (min-width: 501px) and (max-width: 850px) {
	#admin-form {
		height: 50vh;
	}
	#admin-form form {
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	#admin-form label {
		width: 30%;
		text-align: center;
	}
	#admin-form label:first-child {
		margin-top: 0;
	}

	#admin-form input {
		width: 30%;
	}
	#admin-form button {
		margin-top: 20px;
		margin-left: 0;
		width: 30%;
	}
	.slider,
	.underslider {
		display: none;
		visibility: hidden;
	}
	.fila1card {
		width: 45%;
	}

	.fila2home {
		flex-direction: column;
	}

	.fila2home img {
		margin-top: 20px;
	}

	#slider-image-showing img {
		width: 45%;
	}

	.idiomas > div {
		display: none;
	}

	.idiomas h4 {
		text-align: center;
	}
}

/*********************************** responsive - movil ****************************************/

@media only screen and (max-width: 500px) {
	.solomovil {
		display: block;
		visibility: visible;
		text-align: center;
	}

	.solomovil img {
		width: 90%;
		margin-top: 4em;
	}

	#admin-form {
		height: 50vh;
		padding: 20px;
		margin-top: 10vh;
	}

	#admin-form form {
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#admin-form button {
		margin-top: 20px;
		margin-left: 0;
		width: 70%;
	}
	#admin-form label {
		text-align: center;
		width: 70%;
	}

	#admin-form label:first-child {
		margin-top: 0;
	}

	#admin-form input {
		width: 70%;
	}

	#publicar-cambios {
		position: fixed;
		top: 8%;
		left: 0;
	}

	.slider,
	.underslider {
		display: none;
		visibility: hidden;
	}

	.fila1home {
		margin-top: 60px;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.fila1home .fila1card {
		width: 90%;
		border: 1px solid rgb(189, 186, 186);
		border-radius: 15px;
		margin: 20px auto;
		font-size: 1.3em;
	}

	.fila1home img {
		padding-bottom: 10%;
		min-height: 70%;
	}

	.fila2home {
		padding: 10px;
		flex-direction: column;
		align-items: center;
		width: 105%;
	}

	.fila2home p {
		padding: 20px;
		font-size: 1.3em;
	}

	.fila2home img {
		width: 95%;
		margin: 10px auto;
		padding-top: 0;
	}

	.tips-servicios {
		padding-top: 0;
		margin-bottom: 15%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	.tips-servicios > div {
		width: 100%;
		min-height: 250px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.tips-servicios > div img {
		width: 120%;
		height: auto;
	}

	.tips-servicios > div a {
		margin-top: 20px;
	}

	#slider-image-showing {
		width: 100%;
		margin-top: 40px;
	}

	.col3 a {
		margin-top: 70px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.col3 h3 {
		margin-top: 10px;
		padding-left: 5%;
	}

	.col3 hr {
		width: 90%;
		margin-left: 5%;
	}

	.col3 p {
		margin-top: 10px;
		padding-left: 5%;
		padding-right: 5%;
		text-indent: 0;
		font-size: 1.3em;
	}

	.col3 img {
		padding-bottom: 5%;
		margin-top: 20%;
		height: auto;
		width: 70%;
	}

	#slider-image-showing img {
		width: 70%;
		animation: showSlowly 3s ease-in-out;
	}

	/* .idiomas > div {
		width: 50%;
		margin-top: 0px;
	}

	.idiomas div img {
		width: 50px;
		margin: 0 auto;
	} */

	.idiomas > div {
		display: none;
	}
}
