.down {
	height: 0;
}

.img-container {
	position: relative;
	overflow: hidden;
	padding-bottom: 0;
	margin-bottom: 20px;
	height: 50%;
}

.imgresumen {
	display: inline-block;
	width: 100%;
	height: 550px;
	margin-right: 20px;
	margin-bottom: 20px;
	z-index: 2;
	margin: 0;
	padding-bottom: 0;
}

/* #test-img:hover {
	animation: graytocolor 2s ease-in forwards;
} */

@keyframes graytocolor {
	0% {
		filter: grayscale(0.6);
	}
	100% {
		filter: grayscale(0);
	}
}

.servicio {
	padding-bottom: 4em;
	/* position: relative; */
}

.servicio h2 {
	margin-left: 20px;
}

.servicio img {
	width: 200px;
	margin: 20px;
	height: auto;
}

.servicio p.text-test {
	margin: 20px auto 0px auto !important;
	padding: 10px;
	width: 95%;
	background-color: white;
	color: #000f61;
	text-align: justify;
	opacity: 1;
	border-radius: 10px;
}

@keyframes moveup {
	0% {
		bottom: -300px;
	}
	50% {
		bottom: -150px;
		opacity: 0.5;
	}
	100% {
		opacity: 0.9;
		bottom: 0;
	}
}

@keyframes movedown {
	0% {
		opacity: 0;
		transform: translateY(-120px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

/*************acordeon************/
/* #container {
	margin: 0 auto;
	width: 50%;
} */
#accordion input,
#accordion2 input,
#accordion3 input {
	display: none;
}
#accordion label,
#accordion2 label,
#accordion3 label {
	background: #008;
	border-radius: 0.25em;
	cursor: pointer;
	display: block;
	margin-bottom: 0.125em;
	padding: 0.5em 1em;
	z-index: 20;
	color: #efefef;
	font-family: "Abel";
	font-weight: bold;
	font-size: 1.3em;
	min-height: 48px;
	box-sizing: border-box;
}
#accordion label:hover,
#accordion2 label:hover,
#accordion3 label:hover {
	background: #005;
}
#accordion input.selected-service + label,
#accordion2 input.selected-service + label,
#accordion3 input.selected-service + label {
	background: #003;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	color: white;
	margin-bottom: 0;
}

#accordion article.not-selected,
#accordion2 article.not-selected,
#accordion3 article.not-selected {
	display: none;
}
#accordion article.article-selected,
#accordion2 article.article-selected,
#accordion3 article.article-selected {
	display: block;
	border-bottom-left-radius: 0.25em;
	border-bottom-right-radius: 0.25em;
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	border-left: 1px solid rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	padding: 1em;
	animation: movedown 1s ease-in-out forwards;
}

.article-selected p {
	color: #000f61;
}

/*********************************** responsive - movil ****************************************/

@media only screen and (max-width: 600px) {
	.down {
		height: 50px;
	}

	.servicio img {
		display: block;
		margin: 20px auto;
		float: none;
		width: 80%;
		height: auto;
	}

	.servicio p,
	.servicio p.text-test {
		font-size: 1.3em;
		display: block;
		width: 100%;
		text-align: justify;
	}

	#traducciones p {
		color: #000f61;
	}
	#traducciones h3 {
		margin-top: 20px;
		text-align: center;
		color: #000f61;
	}
}
