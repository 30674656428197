/******* imports ********/
@import "https://fonts.googleapis.com/css?family=Anton&subset=latin-ext";
@import "https://fonts.googleapis.com/css?family=Abel";
@import "https://fonts.googleapis.com/css?family=Baloo+Bhaina&subset=latin-ext";

/* "Anton para titulares y Abel para parrafos" */

/****** generales *******/
div,
ul,
li,
section,
body,
aside,
header,
nav,
footer,
label,
input,
textarea {
	box-sizing: border-box;
}

body {
	width: 100%;
	/* max-width: 1200px; */
	background-color: #efefef;
	margin: 0 auto;
	background: url("../img/bg-pattern.png") repeat;
	padding: 0 30px;
	margin: 0;
}

.App {
	width: 100%;
}

/* url("../img/bg-bandera.png") no-repeat right bottom,*/

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Anton";
	color: #333;
	font-weight: normal;
	margin: 0;
}

p,
a,
li,
textarea {
	font-family: "Abel";
	color: #333;
	font-size: 1.2em;
}

.clear {
	clear: both;
	visibility: hidden;
}

section > hr {
	margin: 40px auto;
	width: 70%;
}

.subtext {
	font-size: 0.65em;
	opacity: 0.7;
}

/******** header *********/

header {
	width: 100%;
	padding-top: 20px;
	display: flex;
	align-items: center;
}

#logo,
#claim,
#logo-language-cert {
	display: inline-block;
	vertical-align: middle;
	margin: 0 1rem 0 1rem;
}

#logo {
	width: 25%;
	padding-left: 20px;
}

#logo img {
	width: 100%;
}

#logo-language-cert {
	max-width: 15%;
	padding-right: 20px;
}

#logo-language-cert img {
	width: 100%;
	border-radius: 10px;
}

#claim {
	width: 74%;
	opacity: 0.9;
}

#claim h1 {
	text-align: center;
	font-family: "baloo Bhaina";
	color: #008;
	line-height: 1;
}

button {
	height: 48px;
	background-color: #008;
	padding: 8px 10px;
	border: none;
	border-radius: 5px;
}

button a {
	color: #efefef;
	font-family: "Anton";
	text-transform: uppercase;
	text-decoration: none;
	font-size: 1.5em;
}

button:hover {
	background-color: #005;
	color: #fff;
}

.center {
	text-align: center;
}
.down {
	display: none;
}

/******** menu *********/

nav {
	width: 100%;
	height: 56px;
	background: #005fb2; /* Old browsers */
	background: -moz-linear-gradient(top, #005fb2 0%, #001794 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		#005fb2 0%,
		#001794 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		#005fb2 0%,
		#001794 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005fb2', endColorstr='#001794',GradientType=0 ); /* IE6-9 */
	border-radius: 5px 5px 0 0;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
	position: sticky;
	top: 0;
	left: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
}

#menu-mobile {
	display: none;
}

header ul .mobile-menu-showing {
	display: flex;
	flex-direction: column;
	height: 50vh;
}

nav ul {
	display: flex;
	justify-content: center;
	padding-top: 0.4em;
	padding-right: 0.4em;
}

nav ul li {
	list-style: none;
	display: inline-block;
}

nav ul li a {
	text-decoration: none;
	color: #eee;
	padding: 5px 20px;
	text-transform: uppercase;
	font-family: "Anton";
	font-size: 1em;
	border-radius: 5px;
	line-height: 2.25;
}

nav ul li a:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

section {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	padding: 20px 20px 50px 20px;
}

/* .vmenu {
} */

.vmenu > ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.vmenu > ul > li {
	padding: 0;
	margin: 0;
}

.vmenu > ul > li > a {
	color: #f9f9f9;
	text-decoration: none;
}

.vmenu > ul > li > a:hover {
	color: #fff;
	text-decoration: underline;
	font-weight: bold;
}

footer {
	width: 100%;
	margin: 0 auto 30px auto;
	min-height: 100px;
	color: #eee;
	background: #0048a0; /* Old browsers */
	background: -moz-linear-gradient(top, #0048a0 0%, #001794 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		#0048a0 0%,
		#001794 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		#0048a0 0%,
		#001794 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0048a0', endColorstr='#001794',GradientType=0 ); /* IE6-9 */
	min-height: 240px;
}

footer a {
	color: #efefef;
	text-decoration: none;
}

footer a:hover {
	text-decoration: underline;
}

.foot1,
.foot2,
.foot3,
.foot4 {
	float: left;
	/*border: 1px solid #000;*/
}

.foot1 {
	width: 20%;
	padding: 10px;
}

.foot2 {
	width: 45%;
	padding: 10px 10px 5px 10px;
}

.foot3 {
	width: 20%;
	padding: 10px;
}

.foot4 {
	width: 15%;
	text-align: right;
	padding: 10px;
}

.foot4 img {
	width: 36px;
	margin-left: 10px;
	opacity: 0.8;
	transition: opacity 0.5s ease;
	border: 2px solid #eee;
}

.foot img:hover {
	opacity: 1;
	transition: opacity 0.5s ease;
}

footer .copyright {
	margin: 0;
	text-align: right;
	padding-right: 20px;
	font-size: 0.8em;
	color: #ddd;
}

.footline {
	margin: 0;
}

.mapa {
	width: 520px;
	height: 220px;
}

/******** valoraciones ********/

.valoracion {
	width: 100%;
	max-width: 1200px;
	min-height: 70px;
}

.valoracion img {
	height: 70px;
	width: 70px;
	float: left;
	margin-top: 10px;
}

.valoracion .nombrevalor {
	display: block;
	width: calc(100% - 70px);
	float: left;
}

.valoracion .textovalor {
	box-sizing: border-box;
	display: block;
	width: calc(100% - 70px);
	float: left;
	margin: 0;
	padding-left: 10px;
	padding-right: 10px;
}

#slideshow .slidesjs-pagination {
	text-align: center;
}

#slideshow .slidesjs-pagination li {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	display: inline-block;
}
#slideshow .slidesjs-pagination li a {
	display: block;
	width: 20px;
	height: 20px;
	font-size: 0;
	border-radius: 100%;
	background-color: #fff;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

main #slideshow .slidesjs-pagination li a:hover {
	background-color: #007;
}

#slideshow .slidesjs-pagination li .active {
	background-color: #007;
}

/*********************************** responsive - movil ****************************************/

@media only screen and (max-width: 500px) {
	body {
		margin: 0 auto;
		background-image: url("../img/bg-logo-mobile.png");
		background-position: center top;
		background-size: 90%;
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-color: #efefef;
		padding: 0;
	}

	p {
		text-align: justify;
	}

	.down {
		display: block;
		height: 3em;
		width: 100%;
	}

	.mobile-hide {
		display: none;
		visibility: hidden;
	}

	header {
		display: none;
		visibility: hidden;
	}

	nav {
		width: 100%;
		height: 48px;
		margin: 0;
		position: fixed;
		top: 0;
		box-shadow: none;
		border-radius: 0;
	}

	/* Small menu to be toggled */

	nav #menu-mobile {
		display: flex;
		visibility: visible;
		justify-content: space-around;
		text-align: left;
		height: 100%;
		cursor: pointer;
	}

	nav #menu-mobile img {
		width: 25px;
		height: 25px;
		margin-left: 2%;
		padding-top: 12px;
		line-height: 1.5;
		padding-right: 20%;
	}

	nav #menu-mobile h1 {
		text-align: center;
		color: #f9f9f9;
		padding-top: 3px;
	}

	nav ul {
		height: 100%;
		padding: 0;
		margin: 0;
	}

	.menu-mobile-not-showing img {
		transform: rotate(-90deg);
		margin-top: 0;
		margin-right: 10px;
		transition: transform 0.5s ease-in;
	}

	.menu-mobile-not-showing h1 {
		display: none;
	}

	nav ul #menu-mobile h1 #Up2You-subtest {
		display: none;
	}

	/* Navigation menu mobile */

	nav ul.navigation-menu-showing {
		display: flex;
		flex-direction: column;
		height: 300px;
		width: 100%;
		position: absolute;
		top: -10px;
		z-index: 10000;
	}

	nav ul.navigation-menu-not-showing {
		display: none;
	}

	nav ul li {
		width: 100%;
		height: 48px;
		visibility: visible;
		text-align: center;
		transition: visibility 0.2s ease;
		background-color: #001794;
	}

	nav ul li:last-child {
		box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.4);
	}

	nav ul li a {
		text-align: center;
		width: 100%;
		height: 43px;
		display: block;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		padding: 0;
	}

	footer {
		margin: 0;
		max-width: 100%;
		background-color: #001794;
		display: flex;
		flex-direction: column;
	}

	.foot1,
	.foot2,
	.foot3,
	.foot4 {
		width: 100%;
		background-color: #001794;
	}

	.foot2 iframe.mapa {
		width: 100% !important;
	}

	.foot3 {
		padding: 20px 0;
	}

	.foot3 ul {
		width: 100%;
	}
	.foot3 ul li {
		width: 100%;
		height: 48px;
		text-align: center;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
	}

	.foot3 ul li:last-child {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}

	.foot3 ul li a {
		width: 100%;
		height: 38px;
		font-weight: bold;
		font-size: 1.2em;
		text-align: center;
		display: block;
		padding-top: 10px;
	}

	.foot3 ul li a:hover {
		background-color: rgba(0, 0, 0, 0.5);
		text-decoration: none;
	}

	.foot4 {
		text-align: center;
		padding: 0 10px 10px 10px;
	}

	#slideshow {
		display: none !important;
	}
}

@media only screen and (min-width: 501px) and (max-width: 850px) {
	nav ul:first-child {
		display: none;
		padding-right: 0;
	}
	#navigation-menu.navigation-menu-not-showing {
		padding-left: 0;
	}
	.slider {
		margin-bottom: 3rem;
	}
	.underslider {
		display: none !important;
		height: 2px !important;
	}

	/* .fila1home {
		display: flex;
		flex-wrap: wrap;
		padding-top: 35%;
	}
	.fila1home div {
		width: 50% !important;
	}
	.fila1home div p {
		padding-top: 1rem !important;
	} */
	.tips-servicios {
		display: flex;
		flex-direction: column;
	}
	.tips-servicios div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 100%;
	}
	.tips-servicios h3 {
		padding: 1rem !important;
		margin: 0;
	}
	.tips-servicios img {
		width: 100%;
		height: auto;
	}
	.tips-servicios hr {
		width: 80% !important;
		padding: 0;
		margin: 0 !important;
	}
	.tips-servicios p {
		padding-top: 1rem;
	}

	.idiomas img {
		max-width: 75%;
	}

	footer {
		display: flex;
		flex-direction: column;
	}
	.foot1,
	.foot2,
	.foot3,
	.foot4 {
		width: 100%;
	}

	.foot1,
	.foot2 {
		display: flex;
		justify-content: center;
	}
}
