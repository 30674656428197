#nosotros-container {
	padding: 20px;
	width: 100%;
}

#nosotros-container img {
	width: 180px;
	height: auto;
	margin-top: 8px;
}

#nosotros-container .tarjeta h3,
#nosotros-container .tarjeta h5 {
	margin-top: 8px;
}
